.uras-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 10px;
}

.brand-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.video-container {
    border-left: 0.2px solid rgb(208, 197, 197);
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px;
    align-items: center;  
}

.onscrolldiv{
    height: 900px;
    /* or any height you want */
    overflow-y: scroll;
    /* Always show scrollbar */
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
    -ms-overflow-style: none;
    /* Hide scrollbar for IE/Edge */
}

.onscrolldiv::-webkit-scrollbar{
    display: none; 
}

.vdo-head {
    border-bottom: 1px solid black;
    margin-bottom: 20px;
    font-size: 20px;
    text-transform: uppercase;
    color: red;
}


.vdo {
    width: 200px;
    height: 200px;
}

.product-info-container {
    width: 85%;
}

.uras-vibrator-container {
    margin: 10px 0px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    gap: 30px;
    text-align: justify;
    line-height: 30px;
    /* border-bottom: 1px solid black;
    border-top: 1px solid black; */
}

.detail-btn button {
    margin-top: 20px;
}

.vibrator-content span {
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.vibrator-content p {
    font-size: 13px;
    margin-bottom: 10px;
}

.uras-logo-container {
    margin-bottom: 10px;
}

.mobile-slider{
    display: none;
}

.link{
    text-decoration: none !important;
}

.link:hover{
    text-decoration: none;
    color: red;
}

.titletag{
    color: white;
    cursor: pointer;
    background-color: #e84343;
    padding: 2px 6px;
    border-radius: 2px;
    box-shadow: 0px 2px 5px 3px #dddddd;
}


@media only screen and (max-width: 600px) {
    .brand-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .uras-main-container {
        margin: 0px;
    }

    .product-info-container {
        width: 100%;
    }

    .uras-vibrator-container {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .vibrator-image img{
        width: 100%;
    }

    .vibrator-content span {
        font-size: 18px;
    }

    .vibrator-content p {
        font-size: 12px;
        line-height: normal;
    }

    .video-container {
        width: 100%;
    }

    .vdo-cards{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

}