.manuclass {
    display: flex;
    flex-direction: column;
}

.rowws {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

}

.boxbox {
    padding: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 0.2px solid rgb(181, 181, 181);
}