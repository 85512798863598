.services {
    margin-top: 30px;
    padding: 10px 40px;
    text-align: center;
}

.services h2 {
    color: gray;
    font-weight: 300;
    text-transform: uppercase;
}

.serrrr {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.services p {
    text-align: center;
}

.s-cards {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.service-card {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    /* border: 1px solid rgb(176, 176, 176); */
    align-items: flex-start;
}

.service-image {
    width: 30%;
    padding: 5px;
    box-shadow: 2px 2px 2px 2px rgb(199, 199, 199);
}

.service-content {
    width: 70%;
    padding: 5px;
}

.list-items {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font-size: 13px;
    line-height: 40px;
}

.sub-title {
    font-size: 16px;
    text-align: justify;
    margin: 10px 50px;
}


@media only screen and (max-width: 480px) {
    .services {
        margin: 10px;
        padding: 10px;
    }

    .services p {
        text-align: justify;
    }

    .service-card {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        align-items: center;
    }

    .service-image {
        width: 100%;
    }

    .service-content {
        width: 100%;
    }

    .sub-title {
        margin: 10px 10px;
    }

}