.client{
    margin: 20px 70px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.client-logo{
   width: 250px;
   height: 250px;
   /* border: 0.3px solid rgb(218, 218, 218); */
   box-shadow: 4px 2px 4px 2px rgb(232, 232, 232);
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 5px;
}

.clientHead{
    color: gray;
    text-transform: capitalize;
    font-weight: 400;
    text-shadow: 2px 2px #c8c8c8;
    text-align: center;
}