.brands-main {
    margin: 130px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    flex-wrap: wrap;
    gap: 50px;
}

.brands h4 {
    margin: 20px 10px;
    font-size: 36px;
    text-transform: uppercase;
}


.logo-container {
    position: absolute;
}

.u-logo {
    position: relative;
    bottom: 64px;
    left: 100px;
    width: 110px;
    height: 100px;
}

.n-logo {
    position: relative;
    bottom: 44px;
    left: 100px;
    width: 130px;
    height: 62px;
}

.e-logo {
    position: relative;
    bottom: 35px;
    left: 107px;
    width: 125px;
    height: 50px;
}

.m-logo{
    position: relative;
    bottom: 42px;
    left: 60px;
    width: 200px;
    height: 70px;
}

.sakai-logo{
    position: relative;
    bottom: 40px;
    left: 92px;
    width: 150px;
    height: 70px;
}

.brand-content {
    padding-top: 50px;
}

.brand1 {
    width: 350px;
    padding: 10px 10px;
    background-image: url('https://www.technotalent.in/images/absurdity.png');
    opacity: 1;
    border-bottom: 3px solid red;
}

.brand2 {
    width: 350px;
    padding: 10px 10px;
    background-image: url('https://www.technotalent.in/images/absurdity.png');
    opacity: 1;
    border-bottom: 3px solid red;
}

.brand3 {
    width: 350px;
    padding: 10px 10px;
    background-image: url('https://www.technotalent.in/images/absurdity.png');
    opacity: 1;
    border-bottom: 3px solid red;
}

.brand4{
    width: 350px;
    padding: 10px 10px;
    background-image: url('https://www.technotalent.in/images/absurdity.png');
    opacity: 1;
    border-bottom: 3px solid red;
}

.brand5{
    width: 350px;
    padding: 10px 10px;
    background-image: url('https://www.technotalent.in/images/absurdity.png');
    opacity: 1;
    border-bottom: 3px solid red;
}

.Bttn {
    margin: 10px;
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
}

.know-btn {
    border: none;
    background-color: red;
    color: white;
    border-radius: 5px;
    padding: 6px 15px;
}

.know-btn:hover{
   width: 120px;
   color: white;
}


@media only screen and (max-width: 600px) {
    .brands h4 {
        margin: 20px 0px;
        font-size: 25px;
        text-align: center;
    }

    .brands-main {
        margin: 80px 10px;
        display: flex;
        flex-direction: column;
        gap: 70px;
    }

    .brand1, .brand2, .brand3, .brand4, .brand5 {
        width: 100%;
    }

    .e-logo {
        position: relative;
        bottom: 30px;
        left: 115px;
        width: 100px;
        height: 45px;
    }

    .u-logo {
        position: relative;
        bottom: 65px;
        left: 105px;
        width: 110px;
        height: 100px;
    }

    .n-logo {
        position: relative;
        bottom: 42px;
        left: 95px;
        width: 130px;
        height: 56px;
    }

}