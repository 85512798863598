/* General styles for the footer */
#footer {
  background-color: #f8f9fa;
  padding: 60px 0;
  color: #444;
}

#footer .footer-top {
  padding-bottom: 30px;
}

#footer h4 {
  color: #333;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

#footer p {
  line-height: 24px;
}

#footer a {
  color: #777;
}

#footer a:hover {
  color: #333;
  text-decoration: none;
}

/* Specific styles for footer columns */
#footer .footer-about {
  margin-bottom: 30px;
}

#footer .footer-contact p {
  margin-bottom: 10px;
}

#footer .social-links a:hover {
  color: #a25c5c;
}

#footer .footer-links ul {
  list-style-type: none;
  padding: 0;
}

#footer .footer-links ul li {
  margin-bottom: 10px;
}

#footer .footer-newsletter form {
  margin-top: 20px;
}

#footer .newsletter-form {
  display: flex;
}

.social-links i {
  font-size: 25px;
}

.social-links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.social-links a {
  border: 1px solid black;
  margin-right: 10px;
  padding: 5px 12px;
  border-radius: 100%;
  display: inline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  #footer .footer-links {
    margin-top: 30px;
  }

  #footer .footer-newsletter {
    margin-top: 30px;
  }
}