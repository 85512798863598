/* Style for info items */
.info-item {
    background-color: var(--surface-color);
    padding: 20px 0 30px 0;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.contact .info-item>i {
    font-size: 20px;
    color: red;
    width: 56px;
    height: 56px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    border: 2px dotted red;
}

.info-item h3 {
    margin-top: 10px;
    font-size: 18px;
    color: #553e3e;
    font-weight: 500;
}

.info-item p {
    margin-bottom: 0;
    font-size: 1rem;
}

.php-email-form {
    background-color: white;
    height: 100%;
    padding: 30px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.contact .php-email-form .form-bttn {
    color: white;
    background-color: red;
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 50px;
}


/* Media query for smaller screens */
@media (max-width: 768px) {
    .info-item {
        margin-bottom: 20px;
    }
}