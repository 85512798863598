.download{
    margin-top: 20px;
    margin-bottom: 30px;
}

.catalog-icon{
    font-size: 30px;
    margin: 10px;
}

.catlogCard{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.btn{
    width: 200px;
}