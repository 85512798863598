/* .carousel-arrow .rcs_dotsSet {
    display: none;
} */

.infopage {
    margin: 20px 20px;
    display: flex;
}

.info-content {
    padding: 0px 10px;
    text-align: center;
}


.content h1 {
    color: gray;
    text-transform: capitalize;
    font-weight: 400;
    text-shadow: 2px 2px #c8c8c8;
}

.info-content p {
    text-align: justify;
    line-height: 25px;
    color: rgb(83, 83, 83);
    font-weight: 500;
    text-transform: capitalize;
}

.info-content span {
    font-size: 20px;
    text-transform: capitalize;
    color: red;
    font-weight: 600;
}

.serv-h2 {
    color: gray;
    text-transform: capitalize;
    font-weight: 400;
    text-shadow: 2px 2px #c8c8c8;
}

.serv-p {
    text-align: justify;
    color: rgb(83, 83, 83);
    font-weight: 500;
    text-transform: capitalize;
}

.delivery-icon {
    width: 56px;
    height: 56px;
    color: red;
}

.solution-icon {
    width: 50px;
    height: 50px;
    color: red;
}

.choose-h1 {
    color: gray;
    text-transform: capitalize;
    font-weight: 400;
    text-shadow: 2px 2px #c8c8c8;
}