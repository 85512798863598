.service-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.service-item:hover {
    transform: translateY(-5px);
}

.service-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.service-item p {
    font-size: 16px;
    color: #666;
}

.service-item .icon {
    font-size: 36px;
    color: #333;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
}

.service-item h3 {
    text-align: center;
}

.iconClass {
    padding: 5px;
    border-radius: 5px;
    font-size: 50px;
    color: rgb(65, 64, 64);
    background-color: rgba(230, 61, 61, 0.842);
}

/* Media queries for responsiveness */
@media (max-width: 992px) {
    .service-item {
        margin-bottom: 30px;
    }
}

@media (max-width: 768px) {
    .service-item {
        padding: 15px;
    }
}

@media (max-width: 576px) {
    .service-item {
        padding: 10px;
    }
}